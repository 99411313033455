import { IVisaApplicantParams } from '../../../embassy/models/applicant.interface';

export class UpdateQueryParams {
  static readonly type = '[ResolvedApplication] Update Query Params';
  constructor(public payload: Partial<IVisaApplicantParams>) {}
}

export class GetResolvedVisaApplications {
  static readonly type = '[ResolvedApplication] Get Resolved Applications';
}

export class GetMoreResolvedVisaApplications {
  static readonly type = '[ResolvedApplication] Get More Resolved Applications';
}
