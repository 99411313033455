import { inject, Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IVisaApplication, IVisaApplicationParams } from '../models/application.interface';
import { API_REQUEST_PAGE_SIZE } from '@shared/constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VisaApplicationService {
  apiBaseUrl = environment.apiBaseUrl;
  http = inject(HttpClient);

  getVisaApplications(
    page: number,
    status: number[],
    queryParams: IVisaApplicationParams,
  ): Observable<IVisaApplication> {
    let params = new HttpParams().set('page', page.toString()).set('pageSize', API_REQUEST_PAGE_SIZE.toString());
    if (status.length > 0) status.forEach((status) => (params = params.append('status', status.toString())));
    Object.keys(queryParams).forEach((key) => {
      if (queryParams[key as keyof IVisaApplicationParams]) {
        const value = queryParams[key as keyof IVisaApplicationParams];
        if (value !== undefined && value !== null) {
          params = params.set(key, value.toString());
        }
      }
    });

    return this.http.get<IVisaApplication>(`${this.apiBaseUrl}form/user-response`, { params });
  }
}
