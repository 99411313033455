import { IVisaApplicantParams } from '../../../embassy/models/applicant.interface';

export class UpdateQueryParams {
  static readonly type = '[VisaApplication] Update Query Params';
  constructor(public payload: Partial<IVisaApplicantParams>) {}
}

export class GetPendingVisaApplications {
  static readonly type = '[VisaApplication] Get Pending Applications';
}

export class GetMorePendingVisaApplications {
  static readonly type = '[VisaApplication] Get More Pending Applications';
}
