import { Action, Selector, State, StateContext } from '@ngxs/store';
import { IVisaApplication, VisaApplicationStateModel } from '../../models/application.interface';
import { inject, Injectable } from '@angular/core';
import { VisaApplicationService } from '../../services/visa-application.service';
import { GetMoreResolvedVisaApplications, UpdateQueryParams } from './resolved-application.action';
import { EApplicationStatuses } from '../../../embassy/enums/application-status.enum';
import { catchError, first, of, tap } from 'rxjs';
import { GetResolvedVisaApplications } from './resolved-application.action';

@State<VisaApplicationStateModel>({
  name: 'resolvedApplications',
  defaults: {
    data: [],
    queryParams: {},
    loading: false,
    loadingMore: false,
    page: 1,
    status: [EApplicationStatuses.Approved, EApplicationStatuses.Declined],
    hasMoreData: false,
    error: null,
  },
})
@Injectable()
export class ResolvedApplicationState {
  private visaApplicationService = inject(VisaApplicationService);

  @Selector()
  static getState(state: VisaApplicationStateModel) {
    return state;
  }

  @Selector()
  static data(state: VisaApplicationStateModel) {
    return state.data;
  }

  @Selector()
  static loading(state: VisaApplicationStateModel) {
    return state.loading;
  }

  @Selector()
  static loadingMore(state: VisaApplicationStateModel) {
    return state.loadingMore;
  }

  @Selector()
  static error(state: VisaApplicationStateModel) {
    return state.error;
  }

  @Action(UpdateQueryParams)
  updateQueryParams(ctx: StateContext<VisaApplicationStateModel>, action: UpdateQueryParams) {
    const state = ctx.getState();
    ctx.patchState({
      queryParams: {
        ...state.queryParams,
        ...action.payload,
      },
      page: 1,
      data: [],
    });
  }

  @Action(GetResolvedVisaApplications)
  getPendingApplications(ctx: StateContext<VisaApplicationStateModel>) {
    const { page, queryParams, status } = ctx.getState();
    ctx.patchState({ loading: true, error: null });

    return this.visaApplicationService.getVisaApplications(page, status, queryParams).pipe(
      first(),
      tap((res) => {
        ctx.patchState({
          data: res.data as IVisaApplication[],
          loading: false,
          hasMoreData: res.data.length > 0,
        });
      }),
      catchError((error) => {
        ctx.patchState({
          loading: false,
          error: error.error.errors
            ? error.error.errors[0].description
            : 'Failed to get resolved applications, try again later!',
        });
        return of();
      }),
    );
  }

  @Action(GetMoreResolvedVisaApplications)
  getMorePendingApplications(ctx: StateContext<VisaApplicationStateModel>) {
    const { page, queryParams, status, data } = ctx.getState();
    if (!data.length) return of();
    ctx.patchState({ loadingMore: true, error: null });

    return this.visaApplicationService.getVisaApplications(page + 1, status, queryParams).pipe(
      first(),
      tap((res) => {
        ctx.patchState({
          data: [...data, ...res.data],
          loadingMore: false,
          hasMoreData: res.data.length > 0,
          page: page + 1,
        });
      }),
      catchError((error) => {
        ctx.patchState({
          loadingMore: false,
          error: error.error.errors
            ? error.error.errors[0].description
            : 'Failed to get more resolved applications, try again later!',
        });
        return of();
      }),
    );
  }
}
